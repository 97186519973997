import React, { useState, useEffect } from "react";
import customFetch from "../utils/fetch";
import { motion, AnimatePresence } from "framer-motion";
import { API_URL } from "../utils/constants";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

function LineItem({
  label,
  children,
  className,
}: {
  label: string;
  children?: any;
  className?: string;
}) {
  return (
    <div className={`${className || "w-1/4"} mx-2`}>
      <div className="text-sm text-gray-600">{label}</div>
      {children}
    </div>
  );
}
function Subscription(props: any) {
  const { subscription } = props;

  return (
    <motion.div className="bg-gray-300 my-4 rounded">
      <div
        key={subscription.id}
        className="overflow-hidden pt-4 flex flex-col justify-start"
      >
        <AnimatePresence>
          <div key={subscription.id} className="flex px-4 pb-4">
            <LineItem label="Name">
              {subscription?.vendUserDetails?.first_name}{" "}
              {subscription?.vendUserDetails?.last_name}
            </LineItem>
            <LineItem label="Amount">
              {Number.parseFloat(
                subscription?.subscription?.amount ?? subscription?.amount
              ).toFixed(2)}
            </LineItem>
            {subscription?.subscription?.woocommerce?.salesTax !==
              undefined && (
              <LineItem label="Tax">
                {Number.parseFloat(
                  subscription?.subscription?.woocommerce?.salesTax ?? 0.0
                ).toFixed(2)}
              </LineItem>
            )}
            {subscription?.subscription?.woocommerce?.shipping?.cost !==
              undefined && (
              <LineItem label="Shipping">
                {Number.parseFloat(
                  subscription?.subscription?.woocommerce?.shipping?.cost ?? 0.0
                ).toFixed(2)}
              </LineItem>
            )}
            {subscription?.failureReason && (
              <LineItem label="Failure Reason">
                {
                  JSON.parse(subscription?.failureReason)
                    ?.CreditCardSaleResponse?.Response?.ExpressResponseMessage
                }
              </LineItem>
            )}
            <LineItem label="Service">
              {subscription?.subscription?.service}
            </LineItem>
            <LineItem label="Date">
              {DateTime.fromISO(subscription?.paymentDate).toLocaleString()}
            </LineItem>
            <LineItem label="Status">{subscription?.status}</LineItem>
          </div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default function SubscriptionFullfillment() {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [status, setStatus] = useState("APPROVED");
  const [type, setType] = useState("WOOCOMMERCE");
  const [focused, setFocused] = useState<FocusedInputShape>(null);
  const [services, setServices] = useState({});
  const [csvData, setCsvData] = useState('');
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().add({ day: -1 })
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    moment().add({ day: -1 })
  );

  useEffect(() => {
    if (startDate && endDate && status) {
      customFetch(
        `${API_URL}/subscriptions/completed/${startDate.format(
          "M-D-YYYY"
        )}/${endDate.format("M-D-YYYY")}/${status}/${type}`
      ).then(({ payments }) => {        
        const sortedPayments = JSON.parse(JSON.stringify(payments)).sort(
          (a, b) =>
            new Date(a.paymentDate).getTime() -
            new Date(b.paymentDate).getTime()
        );
        setSubscriptions(sortedPayments);
        
        // Get an aggregate of each service
        const serv = {};
        // let cData=[];
        let cData='Name,Amount,Service,Date,Status\n';
        sortedPayments.forEach((p) => {
                    
          cData+=p.vendUserDetails.first_name+" "+p.vendUserDetails.last_name+','
            + p.subscription.amount+','
            + p.subscription.service+','
            + DateTime.fromISO(p.paymentDate).toLocaleString()+','
            + p.status+'\n';

          
          if (serv[p?.subscription?.service]) {
            serv[p?.subscription?.service] = serv[p?.subscription?.service] + 1;
          } else {
            serv[p?.subscription?.service] = 1;
          }
        });
        setServices(serv);
        
	      // console.log(cData);
	      setCsvData(cData);
        
      });
      
      
    }
  }, [startDate, endDate, status, type]);


  const download = function (data) { 
  
    // Creating a Blob for having a csv file format  
    // and passing the data with type 
    const blob = new Blob([data], { type: 'text/csv' }); 
  
    // Creating an object for downloading url 
    const url = window.URL.createObjectURL(blob) 
  
    // Creating an anchor(a) tag of HTML 
    const a = document.createElement('a') 
  
    // Passing the blob downloading url  
    a.setAttribute('href', url) 
  
    // Setting the anchor tag attribute for downloading 
    // and passing the download file name 
    const d = new Date();

    a.setAttribute('download', (d.getMonth()+1)+'-'+d.getDay()+'-'+d.getFullYear()+'-download.csv'); 
  
    // Performing a download with click 
    a.click() 
} 

// console.log(download(csvData));

  return (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 mb-4 flex flex-col">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Range
        </label>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate"
          endDate={endDate}
          endDateId="endDate"
          isOutsideRange={() => false}
          regular={true}
          withPortal={true}
          minimumNights={0}
          focusedInput={focused}
          onFocusChange={(f) => setFocused(f)}
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
          Payment Type
        </label>
        <select
          value={type}
          onChange={(rec) => setType(rec.target.value)}
          className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        >
          <option value="WOOCOMMERCE">Woocommerce</option>
          <option value="VEND">Vend</option>
        </select>
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
          Payment Status
        </label>
        <select
          value={status}
          onChange={(rec) => setStatus(rec.target.value)}
          className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        >
          <option value="APPROVED">Approved</option>
          <option value="FAILED">Failed</option>
          <option value="OPEN">Open</option>
        </select>

        <button className="focus:outline-none bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg px-5 py-2.5 dark:focus:ring-yellow-900" style={{width:"90px"}} onClick={()=>{
          download(csvData);
        }}>
          Export
        </button>
      </div>


      <div className="bg-white w-full mb-4 flex flex-wrap">
        {Object.keys(services).map((service) => (
          <motion.div className="w-1/4 p-4 flex">
            <div className={`bg-gray-300 w-full h-full p-4 mx-2 rounded`}>
              <div className="text-sm text-gray-600">{service}</div>
              <div className={"text-xl"}>{services[service]}</div>
            </div>
          </motion.div>
        ))}
      </div>
      <div className="bg-white w-full p-6 flex flex-col">
        <div className="flex flex-col">
          {subscriptions.map((sub: any) => {
            return <Subscription key={sub.id} subscription={sub} />;
          })}
        </div>
      </div>
    </div>
  );
}
