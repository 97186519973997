import React, { useEffect, useState } from "react";
import customFetch from "../utils/fetch";
import { API_URL } from "../utils/constants";

export default function RefundOrder() {
  const [data, setData] = useState();
  const [passwordAnswered, setPasswordAnswered] = useState(false);
  const [password, setPassword] = useState("");
  const [vendCustomer, setCustomerId] = useState();
  const [amount, setCost] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState<any>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params: { [key: string]: string } = {};

  new URLSearchParams(window.location.href.split("?")[1]).forEach(
    (entry, key) => {
      params[key] = entry;
    }
  );

  useEffect(() => {
    const dataListener = (event: any) => {
      let eventData;

      if (typeof event?.data === "string") {
        eventData = JSON.parse(event.data);
      } else {
        eventData = event.data;
      }
      // When in vend
      if (eventData?.step === "DATA") {
        setData(eventData);
        if (eventData?.register_sale?.customer_id) {
          setCustomerId(eventData?.register_sale?.customer_id);
          setCost(eventData?.payment?.amount);
        }
      }
    };

    window.addEventListener("message", dataListener, false);

    if (!data) {
      const message = JSON.stringify({
        step: "DATA",
      });
      window.parent.postMessage(message, params.origin);
    }
  }, [vendCustomer, data, params]);

  function handleRefund() {
    setLoading(true);
    customFetch(
      `${API_URL}/orders/${vendCustomer}/processRefund/${Math.abs(
        Number.parseFloat(amount)
      )}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      }
    ).then((data) => {
      setLoading(false);
      if (data.status === 400 || !data?.refunded) {
        setErr(data);
      } else {
        const message = JSON.stringify({
          step: "ACCEPT",
        });
        window.parent.postMessage(message, params.origin);
        setSuccess(data?.refunded);
      }
    });
  }

  if (err) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="text-3xl">Error {err?.message}</div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="text-3xl">Refund Successful</div>
      </div>
    );
  }

  return amount ? (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <label
        htmlFor="refund-password"
        className="block mb-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
      >
        Please enter your password to process the refund
      </label>
      <input
        id="refund-password"
        className="block w-1/2 px-4 py-3 mb-6 leading-tight text-gray-700 border rounded shadow-inner appearance-none bg-gray-50 focus:outline-none focus:bg-white"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <button
        disabled={loading}
        className={`bg-red-600 text-white p-8 rounded ${
          loading ? "disabled opacity-75" : ""
        }`}
        onClick={handleRefund}
      >
        Refund for {Math.abs(amount as any)}
      </button>
    </div>
  ) : (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="text-2xl">Getting Data...</div>
    </div>
  );
}
