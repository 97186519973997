import React, { useEffect, useState } from "react";
import customFetch from "../utils/fetch";
import { API_URL } from "../utils/constants";
import { DateTime } from "luxon";

/**
 * { "id": "ckfynihe22028888vs4g1c5krte", "occurence": "MONTHLY", "amount": 0.01, "vendUserId": "ck94k7z8d00067wp46wffwbpc", "payerId": null, "unitId": null, "service": "AAM - Aesthetic Add on", "updatedAt": "2020-10-07T00:26:00.458Z", "createdAt": "2020-10-07T00:26:00.458Z", "subscriptionPlanId": null, "payments": [ { "id": "ckfyniher2029198vs41iefzvpy", "amount": 0.01, "paymentDate": "2020-10-07T00:26:00.481Z", "status": "APPROVED", "failureReason": null, "transactionId": null, "updatedAt": "2020-10-07T00:26:00.483Z", "createdAt": "2020-10-07T00:26:00.483Z", "subscriptionId": "ckfynihe22028888vs4g1c5krte" }, { "id": "ckfynihf42029308vs4spi17nyv", "amount": 0.01, "paymentDate": "2020-11-06T05:00:00.000Z", "status": "CLOSED", "failureReason": null, "transactionId": null, "updatedAt": "2020-10-07T00:26:00.495Z", "createdAt": "2020-10-07T00:26:00.496Z", "subscriptionId": "ckfynihe22028888vs4g1c5krte" } ], "vendUser": { "id": "ck94k7z8d00067wp46wffwbpc", "vendUserId": "069db350-8d3f-11ea-f6a9-790aa75f9638", "vendUrl": "formulawellnesscenter", "tokenizedCardId": "ckguupgdi6908928us4lwqabu36", "projectId": "ck8o1oghy00045fmqebfggv04", "updatedAt": "2020-04-17T19:02:32.221Z", "createdAt": "2020-04-17T19:02:32.221Z" } }
 * @param subscription
 * @constructor
 */

function lastPaymentDate(subscription: any) {
  subscription?.payments
    .filter((payment) => payment.status === "APPROVED")
    .sort((a, b) => {
      if (DateTime.fromISO(a.paymentDate) > DateTime.fromISO(b.paymentDate)) {
        return -1;
      }
      if (DateTime.fromISO(a.paymentDate) < DateTime.fromISO(b.paymentDate)) {
        return 1;
      }

      return 0;
    });

  return subscription?.payments?.[subscription?.payments?.length - 1]
    ?.paymentDate;
}

function Cell({ name, content }) {
  return (
    <div className="flex flex-col w-1/3 mr-4">
      <div className="text-gray-600">{name}</div>
      <div>{content}</div>
    </div>
  );
}

function Subscription({ subscription, onCancelSubscription }) {
  const date = lastPaymentDate(subscription);

  const disabled = !subscription.payments.some(
    (payment) => payment.status === "OPEN" || payment.status === "FAILED"
  );

  const failed = subscription.payments.some(
    (payment) => payment.status === "FAILED"
  );

  function cancelSubscription() {
    if (disabled) {
      return;
    }
    onCancelSubscription(subscription);
  }

  const text = failed ? "Failed, retry?" : disabled ? "Cancelled" : "Cancel";

  return (
    <div className="flex flex-wrap w-full p-4 m-4 bg-gray-400">
      <Cell name="Name" content={subscription.service} />
      <Cell
        name="Last Paid"
        content={date ? DateTime.fromISO(date).toFormat("DDD") : date}
      />
      <button
        onClick={cancelSubscription}
        className={`rounded px-8 ${
          disabled
            ? "disabled bg-gray-200 opacity-50 cursor-not-allowed"
            : "bg-red-500"
        }`}
      >
        {text}
      </button>
    </div>
  );
}

export default function CancelSubscription() {
  const [customerId, setCustomerId] = useState();
  const [data, setData] = useState();
  const [subscriptions, setSubscriptions] = useState<any[] | undefined>();
  const [subscriptionToCancel, setSubscription] = useState<any>();
  const [cancelling, setCancelling] = useState(false);

  const params: { [key: string]: string } = {};

  new URLSearchParams(window.location.href.split("?")[1]).forEach(
    (entry, key) => {
      params[key] = entry;
    }
  );

  useEffect(() => {
    const dataListener = (event: any) => {
      let eventData;

      if (typeof event?.data === "string") {
        eventData = JSON.parse(event.data);
      } else {
        eventData = event.data;
      }
      // When in vend
      if (eventData?.step === "DATA") {
        setData(eventData);
        if (eventData?.register_sale?.customer_id) {
          setCustomerId(eventData?.register_sale?.customer_id);
        }
      }
    };

    window.addEventListener("message", dataListener, false);

    if (customerId) {
      customFetch(`${API_URL}/subscriptions/vend/${customerId}`).then(
        (subscriptions) => {
          setSubscriptions(subscriptions);
        }
      );
    }

    if (!data) {
      const message = JSON.stringify({
        step: "DATA",
      });
      window.parent.postMessage(message, params.origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  function handleSubscriptionCancel(subscriptionId) {
    setSubscription(subscriptionId);
  }

  function handleSubscriptionCancelConfirmed() {
    setCancelling(true);
    const failed = subscriptionToCancel.payments.some(
      (payment) => payment.status === "FAILED"
    );
    customFetch(
      `${API_URL}/subscriptions/${subscriptionToCancel?.id}/${
        failed ? "retry" : "cancel"
      }`
    ).then((res) => {
      setCancelling(false);
      if (res?.subscription?.cancelled || res?.subscription?.opened) {
        const message = JSON.stringify({
          step: "ACCEPT",
          receipt_html_extra: `Closed subscription service ${subscriptionToCancel?.service}`,
        });
        window.parent.postMessage(message, params.origin);
      }
    });
  }

  subscriptions?.sort((a, b) => {
    if (
      DateTime.fromISO(lastPaymentDate(a)) >
      DateTime.fromISO(lastPaymentDate(b))
    ) {
      return -1;
    }
    if (
      DateTime.fromISO(lastPaymentDate(a)) <
      DateTime.fromISO(lastPaymentDate(b))
    ) {
      return 1;
    }

    return 0;
  });

  return (
    <div className="w-full">
      {customerId ? (
        subscriptionToCancel ? (
          <div className="flex flex-col items-center justify-center px-8 pt-10">
            <div className="pb-8 text-2xl">
              {`Are you sure you want to ${
                subscriptionToCancel.payments.some(
                  (payment) => payment.status === "FAILED"
                )
                  ? "retry"
                  : "cancel"
              } ${subscriptionToCancel?.service}`}
              ?
            </div>
            <button
              disabled={cancelling}
              className={`rounded bg-red-500 mb-12 px-16 py-6 w-full ${
                cancelling ? "disabled opacity-50" : ""
              }`}
              onClick={handleSubscriptionCancelConfirmed}
            >
              {cancelling ? "Sending..." : "Yes"}
            </button>
            <button
              className="w-full px-16 py-6 mb-4 bg-gray-500 rounded"
              onClick={() => setSubscription(undefined)}
            >
              {" "}
              No
            </button>
          </div>
        ) : (
          subscriptions?.map((sub) => (
            <Subscription
              subscription={sub}
              onCancelSubscription={handleSubscriptionCancel}
            />
          ))
        )
      ) : (
        <div className="p-8 text-2xl">Please select a vend customer</div>
      )}
    </div>
  );
}
