import { CheckoutForm } from "./Checkout";
import { EmergencyCheckoutForm } from "./EmergencyCheckout";
import { Router } from "react-router";
import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SubscriptionCreator } from "./Subscription";
import history from "./utils/history";
import Login from "./Login";
import Payments from "./Payments";
import SubscriptionList from "./SubscriptionList";
import { VendCheckoutForm } from "./VendCheckout";
import { VendSubscriptionCreator } from "./VendSubscription";
import RefundOrder from "./RefundOrder";
import CancelSubscription from "./VendSubscription/cancel";
import SubscriptionFulfillment from "./SubscriptionList/fulfillment";

const RedirectParent = () => {
  const [status, setStatus] = useState() as any;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.href) || {};
    const serializedParams: { [name: string]: string } = {};
    //Iterate the search parameters.
    // @ts-ignore
    for (let p of searchParams) {
      serializedParams[p[0]] = p[1];
    }

    const success =
      serializedParams?.ExpressResponseMessage === "Approved" ||
      serializedParams?.ExpressResponseMessage === "PaymentAccount created";

    let message;

    if (success) {
      message = "worldpay-transaction-success";
    } else if (serializedParams?.ExpressResponseMessage === "Declined") {
      message = "worldpay-transaction-declined";
    } else {
      message = "worldpay-transaction-failure";
    }

    setStatus(message);

    const resp = {
      message,
      serializedParams,
    };

    window.parent.postMessage(JSON.stringify(resp), "*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let text;

  if (status === "worldpay-transaction-success") {
    text = "Success";
  } else if (status === "worldpay-transaction-declined") {
    text = "Card Declined";
  } else {
    text = "Error";
  }

  return (
    <div className="flex items-center justify-center w-full h-screen text-5xl">
      {text}
    </div>
  );
};

const uptownProjectId = "ck8o1nrjd00015fmqpf6kadwx";
const parkProjectId = "ck8o1oghy00045fmqebfggv04";
const friscoProjectId = "cka4g2dy407198mn4zw06jwk6";
const statlerProjectId = "ckhfp4wb62057978up4s5c8zw3r";
const scottsdaleProjectId = "ckgsb9ke26361698us41l431prl";
const worthProjectId = "ckkcpbh3114943088vo9vu2k552s";
const southProjectId = "ckq83u10v06228vqtn6ume2zy";
const southLakeProjectId = "ckq39yiakljhgbakasldfngkd";

export default function App() {
  return (
    <Router
      location={history.location}
      navigator={history}
      action={history.action}
    >
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Login />} />
        <Route exact path="/subscriptions" element={<SubscriptionList />} />
        <Route
          exact
          path="/subscriptions/fulfillment"
          element={<SubscriptionFulfillment />}
        />
        <Route exact path="/payments" element={<Payments />} />
        <Route exact path="/refunds" element={<RefundOrder />} />
        <Route
          exact
          path="/park"
          element={<EmergencyCheckoutForm projectId={parkProjectId} />}
        />
        <Route
          exact
          path="/park/checkout"
          element={<CheckoutForm projectId={parkProjectId} />}
        />
        <Route
          exact
          path="/park/checkout/vend"
          element={<VendCheckoutForm projectId={parkProjectId} />}
        />
        <Route
          exact
          path="/park/subscription"
          element={<SubscriptionCreator projectId={parkProjectId} />}
        />
        <Route
          exact
          path="/park/subscription/vend"
          element={
            <VendSubscriptionCreator projectId={parkProjectId} name="Park" />
          }
        />
        <Route
          exact
          path="/lake"
          element={<EmergencyCheckoutForm projectId={southLakeProjectId} />}
        />
        <Route
          exact
          path="/lake/checkout"
          element={<CheckoutForm projectId={southLakeProjectId} />}
        />
        <Route
          exact
          path="/lake/checkout/vend"
          element={<VendCheckoutForm projectId={southLakeProjectId} />}
        />
        <Route
          exact
          path="/lake/subscription"
          element={<SubscriptionCreator projectId={southLakeProjectId} />}
        />
        <Route
          exact
          path="/lake/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={southLakeProjectId}
              name="South Lake"
            />
          }
        />
        <Route
          exact
          path="/south"
          element={<EmergencyCheckoutForm projectId={southProjectId} />}
        />
        <Route
          exact
          path="/south/checkout"
          element={<CheckoutForm projectId={southProjectId} />}
        />
        <Route
          exact
          path="/south/checkout/vend"
          element={<VendCheckoutForm projectId={southProjectId} />}
        />
        <Route
          exact
          path="/south/subscription"
          element={<SubscriptionCreator projectId={southProjectId} />}
        />
        <Route
          exact
          path="/south/subscription/vend"
          element={
            <VendSubscriptionCreator projectId={southProjectId} name="Park" />
          }
        />
        <Route
          exact
          path="/statler"
          element={<EmergencyCheckoutForm projectId={statlerProjectId} />}
        />
        <Route
          exact
          path="/statler/checkout"
          element={<CheckoutForm projectId={statlerProjectId} />}
        />
        <Route
          exact
          path="/statler/checkout/vend"
          element={<VendCheckoutForm projectId={statlerProjectId} />}
        />
        <Route
          exact
          path="/statler/subscription"
          element={<SubscriptionCreator projectId={statlerProjectId} />}
        />
        <Route
          exact
          path="/statler/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={statlerProjectId}
              name="Statler"
            />
          }
        />
        <Route
          exact
          path="/scotts"
          element={<EmergencyCheckoutForm projectId={scottsdaleProjectId} />}
        />
        <Route
          exact
          path="/scotts/checkout"
          element={<CheckoutForm projectId={scottsdaleProjectId} />}
        />
        <Route
          exact
          path="/scotts/checkout/vend"
          element={<VendCheckoutForm projectId={scottsdaleProjectId} />}
        />
        <Route
          exact
          path="/scotts/subscription"
          element={<SubscriptionCreator projectId={scottsdaleProjectId} />}
        />
        <Route
          exact
          path="/scotts/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={scottsdaleProjectId}
              name="Scottsdale"
            />
          }
        />
        <Route
          exact
          path="/worth"
          element={<EmergencyCheckoutForm projectId={worthProjectId} />}
        />
        <Route
          exact
          path="/worth/checkout"
          element={<CheckoutForm projectId={worthProjectId} />}
        />
        <Route
          exact
          path="/worth/checkout/vend"
          element={<VendCheckoutForm projectId={worthProjectId} />}
        />
        <Route
          exact
          path="/worth/subscription"
          element={<SubscriptionCreator projectId={worthProjectId} />}
        />
        <Route
          exact
          path="/worth/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={worthProjectId}
              name="Ft Worth"
            />
          }
        />
        <Route
          exact
          path="/frisco"
          element={<EmergencyCheckoutForm projectId={friscoProjectId} />}
        />
        <Route
          exact
          path="/frisco/checkout"
          element={<CheckoutForm projectId={friscoProjectId} />}
        />
        <Route
          exact
          path="/frisco/checkout/vend"
          element={<VendCheckoutForm projectId={friscoProjectId} />}
        />
        <Route
          exact
          path="/frisco/subscription"
          element={<SubscriptionCreator projectId={friscoProjectId} />}
        />
        <Route
          exact
          path="/frisco/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={friscoProjectId}
              name="Frisco"
            />
          }
        />
        <Route
          path="/uptown"
          element={<EmergencyCheckoutForm projectId={uptownProjectId} />}
        />
        <Route
          exact
          path="/uptown/checkout"
          element={<CheckoutForm projectId={uptownProjectId} />}
        />
        <Route
          exact
          path="/uptown/checkout/vend"
          element={<VendCheckoutForm projectId={uptownProjectId} />}
        />
        <Route
          exact
          path="/uptown/subscription"
          element={<SubscriptionCreator projectId={uptownProjectId} />}
        />
        <Route
          exact
          path="/uptown/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId={uptownProjectId}
              name="Uptown"
            />
          }
        />
        <Route
          path="/test"
          element={
            <EmergencyCheckoutForm projectId="ckafhmfnp0003fdrv878q20pb" />
          }
        />
        <Route
          exact
          path="/test/checkout"
          element={<CheckoutForm projectId="ckafhmfnp0003fdrv878q20pb" />}
        />
        <Route
          exact
          path="/test/checkout/vend"
          element={<VendCheckoutForm projectId="ckafhmfnp0003fdrv878q20pb" />}
        />
        <Route
          exact
          path="/test/subscription"
          element={
            <SubscriptionCreator projectId="ck9dtgb9x0001oarv0twvzwg8" />
          }
        />
        <Route
          exact
          path="/test/subscription/vend"
          element={
            <VendSubscriptionCreator
              projectId="ck9dtgb9x0001oarv0twvzwg8"
              name="Test"
            />
          }
        />
        <Route
          path="/order-complete"
          element={
            <div className="flex items-center justify-center w-screen h-screen text-lg">
              Order has been completed already
            </div>
          }
        />
        <Route
          exact
          path="/subscriptions/cancel"
          element={<CancelSubscription />}
        />
        <Route path="/success" element={<RedirectParent />} />
        <Route
          exact
          path="/order/:orderId"
          element={<CheckoutForm projectId="ck94elfdl00014xrvnkwikods" />}
        />
      </Routes>
    </Router>
  );
}
