import React, { useEffect, useState } from "react";
import { getCustomers } from "../EmergencyCheckout/vend";
import VendClientSelection from "../EmergencyCheckout/vendClientSelection";
import { API_URL } from "../utils/constants";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import CurrencyInput from "react-currency-input-field";
import Checkmark from "../checkmark";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import customFetch from "../utils/fetch";

declare global {
  interface Window {
    setTransactionSuccess: any;
  }
}

const dropDownOptions = [
  { value: "AAM - Aesthetic Add on", text: "Aesthetic Add on $99" },
  { value: "AM - Aesthetic Membership", text: "Aesthetic Membership $150" },
  {
    value: "CW - Concierge Wellness - Doctor",
    text: "Concierge Wellness  - Doctor $250",
  },
  {
    value: "ACW01 - Concierge Wellness - PA/NP",
    text: "Concierge Wellness - PA/NP $150",
  },
  {
    value: "Concierge Wellness - HR IV Therapy Aesthetic",
    text: "Concierge Wellness - HR IV Therapy Aesthetic $374",
  },
  {
    value: "CMBPH - Hormone & Metabolic Body Membership",
    text: "Hormone & Metabolic Body Membership $200",
  },
  {
    value: "HMAM - Hrmne Membership - Aesthetic Membership",
    text: "Hormone Membership  - Aesthetic Membership $198",
  },
  {
    value: "HRTIV - Hormone Membership - IV Therapy Membership",
    text: "Hormone Membership  - IV Therapy Membership $174",
  },
  {
    value: "HMMMP - Hormone Membership - Metabolic Maintenance Plan",
    text: "Hormone Membership - Metabolic Maintenance Plan $174",
  },
  { value: "Hormone Membership", text: "Hormone Membership $99" },
  {
    value: "HRCW - Hormone Replacement Concierge Wellness",
    text: "Hormone Replacement Concierge Wellness - Doctor $400",
  },
  {
    value: "Hormone Replacement Concierge Wellness - PA/NP",
    text: "Hormone Replacement Concierge Wellness - PA/NP $200",
  },
  { value: "IVM - IV Membership", text: "IV Membership $75" },
  { value: "JRLM - Joov Membership", text: "Joov Membership $99" },
  {
    value: "MBP - Metabolic Body Membership",
    text: "Metabolic Body Membership $150",
  },
  {
    value: "MMP - Metabolic Maintenance Plan",
    text: "Metabolic Maintenance Plan $75",
  },
  {
    value: "HRT - Trainer Hormone Replacement Membership",
    text: "Trainer Hormone Replacement Membership $75",
  },
  { value: "TP - Triple Plan - Doctor", text: "Triple Plan - Doctor $400" },
  { value: "TP - Triple Plan - PA/ NP", text: "Triple Plan - PA/NP $250" },
  {
    value: "Akkermansia Membership- Auto Ship",
    text: "Akkermansia Membership- Auto Ship $66.30",
  },
  {
    value: "MedPax",
    text: "MedPax",
  },
  {
    value: "CTBD - Combination of other Plans",
    text: "Other Plan or Combination",
  },
];

export function SubscriptionCreator({ projectId }: { projectId: string }) {
  const [recurrence, setRecurrence] = useState("MONTHLY");
  const [amount, setAmount] = useState<number | null>(0.0);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [customer, setCustomer] = useState<any>();
  const [service, setService] = useState<any>(dropDownOptions[0]);
  const [isCreatingSubscription, setCreatingSubscription] = useState(false);
  const [vendCustomer, setVendCustomer] = useState<any>();
  const [customers, setCustomers] = useState();
  const [date, setDate] = useState<Moment | null>(moment());
  const [focused, setFocused] = useState<boolean | null>(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (!customers) {
      getCustomers().then((customers) => {
        setCustomers(customers?.customers);
      });
    }

    if (customer && vendCustomer?.vendUserId !== customer?.id) {
      customFetch(`${API_URL}/tokens/user/${customer?.id}`).then(
        (vendCustomer) => {
          setVendCustomer(vendCustomer.vendUser);
        }
      );
    }
  }, [customers, customer, vendCustomer]);

  function handleCreateSubmission() {
    if (!vendCustomer?.tokenizedCard?.lastFour) {
      setErrorMessage("No tokenized card for vend user");
    }
    if (
      customer &&
      vendCustomer &&
      date &&
      vendCustomer?.tokenizedCard?.lastFour
    ) {
      setCreatingSubscription(true);
      customFetch(`${API_URL}/subscriptions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vendUserId: vendCustomer?.id,
          amount,
          service,
          recurrence,
          nextPaymentDate: date.startOf("day").toISOString(),
          projectId,
        }),
      }).then(() =>
        setTimeout(() => {
          setCreatingSubscription(false);
          setSuccessMessage(true);
          setCustomer(null);
          setVendCustomer(null);
          setAmount(null);
          setTimeout(() => window.location.reload(), 3000);
        }, 1000)
      );
    }
  }

  return (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 flex flex-col">
        {successMessage && (
          <div className="flex items-center justify-center border-green-300 border p-4 mb-4 w-full">
            Subscription Created!
          </div>
        )}
        {errorMessage && (
          <div className="flex items-center justify-center border-red-300 border p-4 mb-4 w-full">
            {errorMessage}
          </div>
        )}
        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex-1 w-full md:w-3/5 ">
            <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
              Subscription Details
            </div>
            <div className="flex justify-between">
              <div className="w-1/3 pr-4">
                <VendClientSelection
                  customers={customers}
                  onCustomerSelect={(vendCustomer) => setCustomer(vendCustomer)}
                />
              </div>
              <div className="w-2/3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Select Subscription Service
                </label>
                <select
                  value={service}
                  onChange={(service) => setService(service.target.value)}
                  className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                >
                  {dropDownOptions.map((option) => (
                    <option value={option.value}>{option.text}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex pt-4">
              <div className="w-1/3 pr-4">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Next Payment Date
                </label>
                <SingleDatePicker
                  id="date-picker"
                  date={date}
                  focused={focused}
                  onDateChange={(date) => setDate(date)}
                  onFocusChange={(f) => setFocused(f.focused)}
                />
              </div>
              <div className="w-1/3 pr-4">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Frequency
                </label>
                <select
                  value={recurrence}
                  onChange={(rec) => setRecurrence(rec.target.value)}
                  className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                >
                  <option value="MONTHlY">Monthly</option>
                  <option value="YEARLY">Yearly</option>
                </select>
              </div>
              <div className="w-1/3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Subscription Amount
                </label>
                <CurrencyInput
                  id="input-example"
                  className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={0}
                  allowDecimals={true}
                  prefix="$"
                  decimalsLimit={2}
                  onChange={(value) => setAmount(value)}
                />
              </div>
            </div>
            <button
              onClick={handleCreateSubmission}
              disabled={isCreatingSubscription}
              className="cursor-pointer bg-teal-600 hover:bg-teal-500 shadow-xl px-5 py-2 mt-4 inline-block text-teal-100 hover:text-white rounded"
            >
              {isCreatingSubscription
                ? "Creating Subscription..."
                : "Create Subscription"}
            </button>
          </div>
          <div className="flex flex-col py-4 w-full md:w-2/5 md:py-0 md:pb-3 md:pl-8">
            <div>
              <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
                Customer Card
              </div>
            </div>
            <div className="pt-6">
              {vendCustomer && customer && (
                <div className="w-full cursor-pointer p-2 px-4 bg-gray-200 rounded border border-green-400 flex h-full items-center">
                  <div className="flex align-middle pr-4">
                    <div className="rounded-full w-6 h-6 border border-green-600 bg-green-300 flex items-center justify-center">
                      <Checkmark className="w-3 h-3 text-white" />
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="text-sm uppercase">
                      {customer?.first_name} {customer?.last_name}
                    </div>
                    <div style={{ verticalAlign: "sub" }}>
                      <span
                        style={{ lineHeight: "30px", verticalAlign: "middle" }}
                      >
                        **** **** ****
                      </span>{" "}
                      {vendCustomer?.tokenizedCard?.lastFour}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
