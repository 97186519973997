import React, { useState, useEffect } from "react";
import { PencilAltIcon, CheckIcon, XIcon } from "@heroicons/react/solid";
import customFetch from "../utils/fetch";
import { motion, AnimatePresence } from "framer-motion";
import { API_URL } from "../utils/constants";
import TogglePaymentStatus from "./togglePaymentStatus";

function Input({
  label,
  id,
  placeholder,
  onChange,
  error,
  ...rest
}: {
  label: string;
  id?: string;
  placeholder: string;
  onChange?: (e: any) => void;
  error?: boolean;
  type?: string;
}) {
  return (
    <>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </label>
      <input
        className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id={id}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {error && (
        <p className="text-red-500 text-xs italic">
          Please fill out this field.
        </p>
      )}
    </>
  );
}

function LineItem({
  label,
  children,
  className,
}: {
  label: string;
  children?: any;
  className?: string;
}) {
  return (
    <div className={`${className || "w-1/4"} mx-2`}>
      <div className="text-sm text-gray-600">{label}</div>
      {children}
    </div>
  );
}

let cachedSubscriptions: any[] = [];

function PaymentEditField(props: {
  amount: string;
  onAmountChange: (any) => {};
  subscriptionId: string;
}) {
  const { amount, subscriptionId, onAmountChange } = props;
  const [editing, setEditing] = useState(false);
  const [amt, setAmount] = useState(amount);

  async function handleUpdateAmount() {
    const resp = await customFetch(
      `${API_URL}/subscriptions/${subscriptionId}/updateAmount`,
      {
        method: "POST",
        body: JSON.stringify({
          amount: Number.parseFloat(amt).toFixed(2),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    onAmountChange(resp.subscription);
    // Do a thing here
    setEditing(false);
  }

  return editing ? (
    <div className="flex">
      <input
        className="w-20 p-2 rounded"
        value={amt}
        onChange={(e) => setAmount(e.target.value)}
      />
      <CheckIcon
        onClick={handleUpdateAmount}
        className="ml-4 w-6 hover:text-green-600 cursor-pointer"
      />
      <XIcon
        onClick={() => setEditing(false)}
        className="ml-2 w-6 hover:text-red-600 cursor-pointer"
      />
    </div>
  ) : (
    <span className="flex">
      {Number.parseFloat(amount).toFixed(2)}
      <PencilAltIcon
        onClick={() => setEditing(true)}
        className="w-4 ml-2 cursor-pointer"
      />
    </span>
  );
}

function Subscription(props: any) {
  const [view, setView] = useState(false);
  const { subscription, onAmountChange } = props;
  const payments = JSON.parse(JSON.stringify(subscription?.payments)).sort(
    (a, b) =>
      new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime()
  );

  return (
    <motion.div className="bg-gray-300 m-4 rounded">
      <div
        key={subscription.id}
        className="overflow-hidden pt-4 flex flex-col justify-start"
      >
        <AnimatePresence>
          <div key={subscription.id} className="flex px-4 pb-4">
            <LineItem label="Name">
              {subscription?.vendUser?.details?.first_name}{" "}
              {subscription?.vendUser?.details?.last_name}
            </LineItem>
            <LineItem label="Amount">
              <PaymentEditField
                subscriptionId={subscription.id}
                onAmountChange={onAmountChange}
                amount={subscription.amount}
              />
            </LineItem>
            <LineItem label="Service">{subscription?.service}</LineItem>
          </div>
          {view && (
            <motion.div
              key={subscription.id + "payment"}
              exit={{ height: 0 }}
              initial={{ height: 0 }}
              animate={{ height: "100%" }}
            >
              {payments.map((payment: any) => {
                let paymentStatusColor;
                if (payment.status === "FAILED") {
                  paymentStatusColor = "text-red-500";
                } else if (payment.status === "OPEN") {
                  paymentStatusColor = "text-yellow-600";
                } else if (payment.status === "CLOSED") {
                  paymentStatusColor = "text-gray-700";
                } else {
                  paymentStatusColor = "text-green-500";
                }
                return (
                  <motion.div
                    key={payment.id}
                    className="flex mx-4 mb-2 bg-gray-200 justify-between rounded"
                  >
                    <div className="flex p-2 flex-1">
                      <LineItem className={paymentStatusColor} label="Status">
                        {payment.status}
                      </LineItem>
                      <LineItem label="Amount">{payment.amount}</LineItem>
                      <LineItem label="Payment Date">
                        {new Date(payment.paymentDate).toLocaleString()}
                      </LineItem>
                    </div>
                    <TogglePaymentStatus
                      payment={payment}
                      onStatusChange={props.onPaymentChange}
                    />
                  </motion.div>
                );
              })}
            </motion.div>
          )}
          {subscription.payments?.length > 0 && (
            <div
              onClick={() => setView(!view)}
              className="flex z-10 rounded-b text-gray-700 bg-gray-300 hover:text-gray-100 items-center justify-center p-1 hover:bg-gray-600 cursor-pointer w-full"
            >
              More Info
            </div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default function SubscriptionList() {
  const [subscriptions, setSubscriptions] = useState<any[]>([]);

  useEffect(() => {
    customFetch(`${API_URL}/subscriptions`).then(({ subscriptions }) => {
      cachedSubscriptions = subscriptions;
      setSubscriptions(subscriptions);
    });
  }, []);

  function handleAmountChange(subscription: any) {
    const newSubscriptions = subscriptions.slice();
    const subIndex: number = newSubscriptions.findIndex((sub: any) => {
      return sub.id === subscription.id;
    });

    if (subIndex !== -1) {
      newSubscriptions[subIndex] = subscription;
    }

    setSubscriptions(newSubscriptions);
  }

  function handlePaymentChange(payment: any) {
    const newSubscriptions = subscriptions.slice();
    const subIndex: any = newSubscriptions.findIndex((sub: any) => {
      return sub.payments.some((pymnt: any) => {
        return pymnt.id === payment.id;
      });
    });
    if (subIndex !== -1) {
      const paymentIndex = newSubscriptions[subIndex].payments.findIndex(
        (pmt: any) => pmt.id === payment.id
      );
      newSubscriptions[subIndex].payments[paymentIndex] = payment;
    }
    setSubscriptions(newSubscriptions);
  }

  function handlePaymentFilterByName(e: any) {
    const value = e?.target?.value?.toLowerCase();
    const inputLength = value?.length;
    const newSubs = cachedSubscriptions.filter(
      (sub: any) =>
        sub?.vendUser?.details?.first_name
          ?.toLowerCase()
          .slice(0, inputLength) === value ||
        sub?.vendUser?.details?.last_name
          ?.toLowerCase()
          .slice(0, inputLength) === value ||
        `${sub?.vendUser?.details?.first_name} ${sub?.vendUser?.details?.last_name}`
          .toLowerCase()
          .slice(0, inputLength) === value
    );
    setSubscriptions(newSubs);
  }

  return (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 flex flex-col">
        <div className="p-4">
          <Input
            label="Filter by name"
            onChange={handlePaymentFilterByName}
            placeholder="John Doe"
          />
        </div>
        <div className="flex flex-col">
          {subscriptions.map((sub: any) => {
            return (
              <Subscription
                key={sub.id}
                subscription={sub}
                onPaymentChange={handlePaymentChange}
                onAmountChange={handleAmountChange}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
