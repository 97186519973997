import React, { useState } from "react";
import Autosuggest from "react-autosuggest";

export default function VendClientSelection({
  customers,
  onCustomerSelect,
}: {
  customers: any;
  onCustomerSelect: (suggestion: any) => void;
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestion, setSuggestion] = useState("");
  const [, setSuggestionData] = useState({});

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionsFetchRequested({ value }: { value: any }) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const suggestions = customers.filter(
      (customer: any) =>
        customer?.first_name?.toLowerCase().slice(0, inputLength) ===
          inputValue ||
        customer?.last_name?.toLowerCase().slice(0, inputLength) ===
          inputValue ||
        `${customer.first_name} ${customer.last_name}`
          .toLowerCase()
          .slice(0, inputLength) === inputValue
    );
    setSuggestions(suggestions);
  }

  function getSuggestionValue(suggestion: any) {
    const name = `${suggestion.first_name} ${suggestion.last_name}`;
    setSuggestionData(suggestion);
    setSuggestion(name);
    onCustomerSelect(suggestion);
    return name;
  }

  function renderSuggestion(sugg: any) {
    return (
      <div className="p-4 hover:bg-gray-200 cursor-pointer">
        <div>{`${sugg.first_name} ${sugg.last_name}`}</div>
        <div className="text-xs text-gray-700">Email: {sugg.email}</div>
        <div className="text-xs text-gray-700">Phone: {sugg.phone}</div>
        <div className="text-xs text-gray-700">Mobile: {sugg.mobile}</div>
      </div>
    );
  }

  function renderSuggestionsContainer({
    containerProps,
    children,
  }: {
    containerProps: any;
    children: any;
  }) {
    return (
      <div
        {...containerProps}
        style={{
          maxHeight: "400px",
        }}
        className="fixed bg-white shadow-sm shadow-lg rounded overflow-scroll z-10"
      >
        {children}
      </div>
    );
  }

  const inputProps = {
    placeholder: "Customer name",
    value: suggestion,
    className:
      "appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white",
    onChange: (suggestion: any) => {
      if (suggestion.target.value || suggestion.target.value === "") {
        setSuggestion(suggestion?.target?.value || "");
      }
    },
  };

  return (
    <>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        Vend Customer
      </label>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        inputProps={inputProps}
      />
    </>
  );
}
