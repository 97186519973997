import React from "react";
import customFetch from "../utils/fetch";
import { API_URL } from "../utils/constants";

export default function TogglePaymentStatus({ payment, onStatusChange }) {
  function handleStatusToggle() {
    if (payment.status !== "APPROVED") {
      const status = payment.status === "CLOSED" ? "OPEN" : "CLOSED";
      customFetch(`${API_URL}/subscriptions/payment/${payment.id}`, {
        method: "POST",
        body: JSON.stringify({
          status,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(({ payment }) => {
        onStatusChange(payment);
      });
    }
  }

  const canClose = payment.status !== "APPROVED";
  return (
    <button
      onClick={handleStatusToggle}
      className={`self-center cursor-pointer ${
        canClose
          ? payment.status === "CLOSED"
            ? "hover:bg-green-500"
            : "hover:bg-red-400"
          : "opacity-50 disabled"
      } transition rounded-r h-full p-5`}
    >
      {payment.status === "CLOSED" || payment.status === "FAILED"
        ? `${payment.status} to Open`
        : "Close"}
    </button>
  );
}
