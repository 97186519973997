import merge from "lodash.merge";
import history from "./history";

function getUrlParameter(name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&#]" + name + "=([^?&#]*)");
  var results = regex.exec(window.location.href);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export const webTokenKey = "login-token";

function lsTest() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export default async function customFetch(
  url: RequestInfo,
  options?: RequestInit,
  navigateToLoginOnFail = true
) {
  const token = getUrlParameter("token");
  let webToken = token;
  if (lsTest()) {
    webToken = token ? token : localStorage.getItem(webTokenKey);
  }
  const defaultOptions: Partial<RequestInit> = {
    headers: {
      Authorization: `Bearer ${webToken}`,
    },
  };

  const res = await fetch(url, merge(defaultOptions, options));
  if ((res.status === 401 || res.status === 403) && navigateToLoginOnFail) {
    history.push("/login", { from: history.location });
    window.location.reload();
  } else {
    return res && res.json();
  }
}
