import React, { useEffect, useState } from "react";
import { getCustomers } from "./vend";
import { API_URL, isDev } from "../utils/constants";
import customFetch from "../utils/fetch";
const params: { [key: string]: string } = {};

new URLSearchParams(window.location.href.split("?")[1]).forEach(
  (entry, key) => {
    params[key] = entry;
  }
);

function Input({
  label,
  id,
  placeholder,
  onChange,
  error,
  ...rest
}: {
  label: string;
  id?: string;
  placeholder: string;
  onChange?: (e: any) => void;
  error?: boolean;
  type?: string;
}) {
  return (
    <>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </label>
      <input
        className="appearance-none block w-full bg-gray-200 shadow-inner text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        id={id}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {error && (
        <p className="text-red-500 text-xs italic">
          Please fill out this field.
        </p>
      )}
    </>
  );
}

declare global {
  interface Window {
    setTransactionSuccess: any;
  }
}

function updateOrderStatusToClosed(
  orderId: string,
  worldpayReturn: string,
  orderDetails: string
) {
  customFetch(`${API_URL}/orders/${orderId}/close`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ worldpayReturn, orderDetails }),
  });
}

async function createVendTokenizedCard(
  projectId: string,
  vendCustomer: any,
  ccTokenization: string,
  lastFour: string
) {
  await customFetch(`${API_URL}/vend/${vendCustomer}/saveTokenizedCard`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ projectId, ccTokenization, lastFour }),
  });
}

async function createTokenizeOrder(projectId: string) {
  const setupRet = await customFetch(`${API_URL}/orders/tokenizeCardOnly`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ projectId }),
  });
  return setupRet.order;
}

let sentOrder = false;

export function EmergencyCheckoutForm({ projectId }: { projectId: string }) {
  const [transactionSetupId, setSetupId] = useState("");
  const [customerId, setCustomerId] = useState();
  const [orderDetails, setOrderDetails] = useState("");
  const [orderId, setOrderId] = useState("");
  const [customers, setCustomers] = useState<any[]>();
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [stack, setStack] = useState<any[]>([]);

  useEffect(() => {
    if (!customers) {
      getCustomers().then((customers) => {
        if (isDev) {
          setCustomers([
            {
              id: "0adfd74a-159b-11e9-fa42-508e4a645a80",
              first_name: "Test",
              last_name: "Faerber",
              email: "test@test.com",
            },
            ...customers?.customers,
          ]);
        } else {
          setCustomers(customers.customers);
        }
      });
    }
    if (!orderId) {
      createTokenizeOrder(projectId).then((order) => {
        setOrderId(order?.id);
        setSetupId(order?.transactionSetupId);
      });
    }
    const onSuccess = (event: any) => {
      const eventData =
        typeof event.data === "string" ? JSON.parse(event.data) : event.data;

      stack.push("Received Event");
      setStack(stack);

      // When in vend
      if (eventData?.step === "DATA") {
        stack.push("Received Data Event");
        setStack(stack);
        console.log("Setting data");
        setData(eventData);
        if (eventData?.register_sale?.customer_id) {
          setCustomerId(eventData?.register_sale?.customer_id);
        }
      }

      if (
        eventData?.message === "worldpay-transaction-success" &&
        customerId &&
        orderId &&
        !sentOrder
      ) {
        setError(false);
        sentOrder = true;
        stack.push("Closing Order");
        setStack(stack);
        updateOrderStatusToClosed(
          orderId,
          eventData?.serializedParams,
          orderDetails
        );
        createVendTokenizedCard(
          projectId,
          customerId,
          eventData?.serializedParams?.PaymentAccountID,
          eventData?.serializedParams?.LastFour
        );
      }
    };

    window.removeEventListener("message", onSuccess, false);
    stack.push("Set Event");
    setStack(stack);
    window.addEventListener("message", onSuccess, false);

    if (!data) {
      stack.push("Sending Event");
      setStack(stack);
      const message = JSON.stringify({
        step: "DATA",
      });
      window.parent.postMessage(message, params.origin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customers, orderId, projectId, orderDetails, data]);

  return (
    <div className="bg-gray-200 flex items-center flex-col max-w-screen-lg mx-auto p-6">
      <div className="bg-white w-full p-6 flex flex-col-reverse md:flex-row">
        <div className="flex-1 w-full md:w-3/5 ">
          <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
            Checkout Details
          </div>
          {error && (
            <div className="w-full p-5 border border-red-600 border-solid">
              You need to select a customer!
            </div>
          )}
          <div className="flex justify-between">
            <div className="w-1/2">
              <Input
                label="Order Note"
                placeholder="Info about order"
                onChange={(e) => setOrderDetails(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col py-4 w-1/2 justify-between">
          <div>
            <div className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-6">
              Tokenize Card
            </div>
          </div>
          <div>
            {transactionSetupId !== "" && customerId && (
              <iframe
                className="w-full h-64 overflow-hidden"
                title="hosted-checkout"
                src={`https://${
                  isDev ? "certtransaction" : "transaction"
                }.hostedpayments.com/?TransactionSetupID=${transactionSetupId}`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
